


import Component from 'vue-class-component';
import {Vue, Watch} from 'vue-property-decorator';
import { Validations } from 'vuelidate-property-decorators';

import {required} from 'vuelidate/lib/validators';
import {mapGetters} from 'vuex';

import { TCodeList } from '@/_types/promo-page/promo-page.type';
import { TVuelidateRuleSet } from '@/_types/vuelitation-rule-set.type';
import ValidationHelper from '@/_helpers/validation.helper';

type TCreateExponent = {
  title: string;
  code: string;
  external_id: string;
}

@Component({
  name: 'create-exponents',
  components: {},
  computed: {
    ...mapGetters('promoPageStore', ['promoPageCodeList']),
  },
})

export default class CreateExponents extends Vue {

  @Validations()
  public readonly validations: TVuelidateRuleSet<TCreateExponent> = {
    formData: {
      title: {
        required,
      },
      external_id: {
        required,
        uniqueExternalId: (): boolean => {
          return this.isUniqueExternalId();
        },
        isValidExternalId(externalId: string): boolean {
          return ValidationHelper.isValidExternalId(externalId);
        }
      },
    },
  }

  public promoPageCodeList: TCodeList[];
  public formData: TCreateExponent = {
    title: '',
    code: '',
    external_id: '',
  };

  @Watch('formData.title', {immediate: false})
  private onFormDataTitleChange(): void {
    if(!this.formData.title) {
      this.formData.external_id = '';
    }
  }

  @Watch('formData.external_id', {immediate: false})
  private onFormDataExternalIdChange(): void {
    this.formData.code = '';

    // if(!this.formData.external_id) {
    //   this.onUniqueExternalId = true;
    // }
  }

  private static randomChars(): string {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 3; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }

  private async postPromoPage(): Promise<void> {
    this.generateAccessKey();

    if (this.$v.formData.$pending || this.$v.formData.$invalid || !this.formData.code) {
      return;
    }
    this.$v.$reset();
    await this.$store.dispatch('promoPageStore/createPromoPage', {
      eventId: this.$route.params.eventId,
      promoPageData: this.formData
    });
    await this.$store.dispatch('promoPageStore/getPromoPageCodeList', {eventId: this.$route.params.eventId});
    await this.$store.dispatch('promoPageStore/getPromoPageListAll', {eventId: this.$route.params.eventId});

    this.formData.title = '';
    this.formData.external_id = '';
    this.formData.code = '';
  }

  private generateAccessKey(): void {
    this.$v.formData.$touch();

    if (this.$v.formData.$pending || this.$v.formData.$invalid) {
      return;
    }

    this.formData.code = `${(~~(Math.random() * 1e8)).toString(19)}${CreateExponents.randomChars()}`;

  }

  private isUniqueExternalId(): boolean {
    for (let i = 0; i < this.promoPageCodeList.length; i++) {
      if (this.promoPageCodeList[i].external_id === this.formData.external_id) {
        return false;
      }
    }
    return true;
  }
}
