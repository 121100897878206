


import Component from 'vue-class-component';
import {Vue} from 'vue-property-decorator';

import IconSquareShare from '@/_modules/icons/components/icon-square-share.vue';
import IconSquareEdit from '@/_modules/icons/components/icon-square-edit.vue';
import IconSquareDelete from '@/_modules/icons/components/icon-square-delete.vue';
import SimplePopup from '@/_modules/controls/components/simple-popup/simple-popup.vue';
import {mapGetters} from 'vuex';

import {TPromoPage, TCodeList} from '@/_types/promo-page/promo-page.type';

@Component({
  name: 'exponents-list',
  components: {IconSquareShare, IconSquareEdit, IconSquareDelete, SimplePopup},
  computed: {
    ...mapGetters('promoPageStore', ['promoPageListAll', 'promoPageCodeList']),
  },
})

export default class ExponentsList extends Vue {
  public externalId: string | null = null;
  public codeId: string | null = null;
  public isShowConfirmation: boolean = false;
  public promoPageListAll: TPromoPage[];
  public promoPageCodeList: TCodeList[];

  public get extendedPromoPageList(): any {
    if (this.promoPageListAll && this.promoPageCodeList) {
      return this.promoPageListAll.map((item) => {
        const result = this.promoPageCodeList.find(obj => {
          if (obj.external_id === item.external_id) {
            Object.assign(item, obj);
          }
        });
        return result || item;
      });
    }
  }

  public created(): void {
    this.$store.dispatch('promoPageStore/getPromoPageListAll', {eventId: this.$route.params.eventId});
    this.$store.dispatch('promoPageStore/getPromoPageCodeList', {eventId: this.$route.params.eventId});
  }

  // @Watch('promoPageListAll', { immediate: false, deep: true })
  // private onIsPromoPageListAllChanged(): void {
  //   this.exponentsList = this.extendedPromoPageList;
  //   console.log(this.promoPageListAll, 'list all');
  // }
  //
  // @Watch('promoPageCodeList', { immediate: false, deep: true })
  // private onIsPromoPageCodeListChanged(): void {
  //   this.exponentsList = this.extendedPromoPageList;
  // }

  private async deleteCompany(externalId: string, codeId: string): Promise<void> {
    this.externalId = externalId;
    this.isShowConfirmation = true;
    this.codeId = codeId;
  }

  private async confirmedDelete(isConfirmed: boolean): Promise<void> {
    if (isConfirmed && this.externalId) {
      const payload = {
        eventId: this.$route.params.eventId,
        externalId: this.externalId,
      };

      await this.$store.dispatch('promoPageStore/deletePromoPage', payload); // delete doesn't work yet: status 500 (already fixing)
      // await this.$store.dispatch('promoPageStore/deletePromoPageByCode', payload);
      await this.$store.dispatch('promoPageStore/getPromoPageCodeList', {eventId: this.$route.params.eventId});
      await this.$store.dispatch('promoPageStore/getPromoPageListAll', {eventId: this.$route.params.eventId});
      this.isShowConfirmation = false;
      this.externalId = null;
    } else {
      this.isShowConfirmation = false;
      this.externalId = null;
    }
  }
}
