


import Component from 'vue-class-component';
import {Vue, } from 'vue-property-decorator';

import ExponentsList from '@/_modules/promo-cabinet/components/cabinet-exponents/exponents-list/exponents-list.vue';
import CreateExponents from '@/_modules/promo-cabinet/components/cabinet-exponents/create-exponents/create-exponents.vue';

@Component({
  name: 'cabinet-exponents',
  components: { ExponentsList, CreateExponents}
})

export default class CabinetExponents extends Vue {}
